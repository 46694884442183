const skillAnimation = (skills) => {
  const newSkills = skills.querySelectorAll(".skills__skill-container");

  newSkills.forEach((skill, id) => {
    setTimeout(() => {
      skill.classList.add(`skills__skill-container_show`);
    }, 500 + id * 150);
  });
};
export default skillAnimation;
