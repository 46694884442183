import "./Navigation.css";
import { Link } from "react-scroll";

const Navigation = ({ isHover }) => {
  return (
    <nav className="navigation">
      <ul className="navigation__list">
        <li className="navigation__item">
          <Link
            className="navigation__link"
            to="home"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            style={{ justifyContent: `${!isHover ? "center" : "flex-start"}` }}
          >
            <i className="navigation__icon navigation__icon_type_home"></i>
            <label
              className={`navigation__link-lable ${
                isHover ? "navigation__link-lable_show" : ""
              }`}
            >
              Home
            </label>
          </Link>
        </li>

        <li className="navigation__item">
          <Link
            className="navigation__link"
            to="about"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            style={{ justifyContent: `${!isHover ? "center" : "flex-start"}` }}
          >
            <i className="navigation__icon navigation__icon_type_about"></i>
            <label
              className={`navigation__link-lable ${
                isHover ? "navigation__link-lable_show" : ""
              }`}
            >
              About
            </label>
          </Link>
        </li>

        <li className="navigation__item">
          <Link
            className="navigation__link"
            to="skills"
            spy={true}
            smooth={true}
            offset={-290}
            duration={500}
            style={{ justifyContent: `${!isHover ? "center" : "flex-start"}` }}
          >
            <i className="navigation__icon navigation__icon_type_skills"></i>
            <label
              className={`navigation__link-lable ${
                isHover ? "navigation__link-lable_show" : ""
              }`}
            >
              Skills
            </label>
          </Link>
        </li>

        <li className="navigation__item">
          <Link
            className="navigation__link"
            to="projects"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            style={{ justifyContent: `${!isHover ? "center" : "flex-start"}` }}
          >
            <i className="navigation__icon navigation__icon_type_work"></i>
            <label
              className={`navigation__link-lable ${
                isHover ? "navigation__link-lable_show" : ""
              }`}
            >
              Projects
            </label>
          </Link>
        </li>

        <li className="navigation__item">
          <Link
            className="navigation__link"
            to="contact"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            style={{ justifyContent: `${!isHover ? "center" : "flex-start"}` }}
          >
            <i className="navigation__icon navigation__icon_type_contact"></i>
            <label
              className={`navigation__link-lable ${
                isHover ? "navigation__link-lable_show" : ""
              }`}
            >
              Contact
            </label>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Navigation;
