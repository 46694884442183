import "./About.css";
import { useEffect } from "react";
import animateLetters from "../../utils/letterAnimation";

import FadeIn from "../FadeIn/FadeIn";

import SlideShow from "../SlideShow/SlideShow";

const About = () => {
  useEffect(() => {
    const element = document.querySelector(".about__title");

    const observer = new IntersectionObserver(
      (enteries) => {
        enteries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateLetters("about__letter");
          }
        });
      },
      {
        threshold: 1,
      }
    );
    observer.observe(element);
  }, []);

  return (
    <FadeIn>
      <section className="about" id="about">
        <div className="about__container">
          <h2 className="about__title">
            <span className="about__letter">A</span>
            <span className="about__letter">b</span>
            <span className="about__letter">o</span>
            <span className="about__letter">u</span>
            <span className="about__letter">t</span>
            &nbsp;
            <span className="about__letter">m</span>
            <span className="about__letter">e</span>
          </h2>
          <div className="about__info-container">
            <p className="about__text">
              I'm a full-stack web developer with a strong background in
              computer science. <br></br>Recently, I graduated from the Yandex
              Immersive Web Development Bootcamp.<br></br> I am passionate about
              coding and solving problems through code,<br></br> and I am
              excited to work alongside other amazing programmers and learn so
              much more! <br></br> In my free time and days off you will
              probably find me surfing 🏄 or riding my motorcycle covered in mud
              🏍
            </p>
            <SlideShow />
          </div>
        </div>
      </section>
    </FadeIn>
  );
};
export default About;
