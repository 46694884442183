import { useEffect } from "react";
import "./Project.css";

const Project = ({ title, image, description, live, github }) => {
  return (
    <li className="projects__project">
      <img className="projects__image" src={image} alt="project"></img>
      <div className="projects__info-cover">
        <div className="projects__info">
          <div className="projects__text-wrap">
            <p className="projects__name">{title}</p>
            <p className="projects__text">{description}</p>
          </div>

          <div className="projects__links">
            <button className="projects__link">
              <a
                href={live}
                target="_blank"
                rel="noreferrer"
                className="projects__link-url"
              >
                Live
              </a>
            </button>{" "}
            <button className="projects__link">
              <a
                href={github}
                target="_blank"
                rel="noreferrer"
                className="projects__link-url"
              >
                Repo
              </a>
            </button>{" "}
          </div>
        </div>
      </div>
    </li>
  );
};
export default Project;
