import "./Hippo.css";
import { useEffect } from "react";
import { gsap, Power2 } from "gsap";

import cv from "../../utils/ephraim-cv.docx";

const Hippo = () => {
  useEffect(() => {
    const button = document.querySelector(".hippo");

    button.addEventListener("mouseenter", enterButton);
    button.addEventListener("mouseleave", leaveButton);

    function enterButton() {
      mouthOpen.play();
    }
    function leaveButton() {
      mouthOpen.reverse();
    }

    // --------------
    // Hover animaton
    // --------------

    const mouthSpeed = 0.3;
    const easeType = Power2.easeOut;
    const mouthOpen = gsap.timeline({ paused: true });
    mouthOpen.to(".mouth-back", mouthSpeed, { ease: easeType, y: -70 }, 0);
    mouthOpen.to(
      ".tongue",
      mouthSpeed + mouthSpeed * 0.5,
      { ease: easeType, y: -70 },
      0
    );
    mouthOpen.to(
      ".teeth",
      mouthSpeed,
      { ease: easeType, y: -70, scaleY: 1.2 },
      0
    );
    mouthOpen.to(
      ".body",
      mouthSpeed,
      { ease: easeType, scaleY: 1.06, transformOrigin: "center bottom" },
      0
    );
    mouthOpen.to(".freckles", mouthSpeed, { ease: easeType, y: -10 }, 0);
    mouthOpen.to(".ears", mouthSpeed, { ease: easeType, y: 6 }, 0);
    mouthOpen.to(".eye-right", mouthSpeed, { ease: easeType, x: -2 }, 0);
    mouthOpen.to(".eye-left", mouthSpeed, { ease: easeType, x: 2 }, 0);
    mouthOpen.to(".eyes", mouthSpeed, { ease: easeType, y: 2 }, 0);
    mouthOpen.to(".nostrils", mouthSpeed, { ease: easeType, y: -6 }, 0);

    // ----------
    // Ear wiggle
    // ----------

    const earWiggle = gsap.timeline({ paused: true });
    earWiggle.set(".ear-right", { transformOrigin: "center center" });
    earWiggle.to(".ear-right", 0.1, { rotation: 45 });
    earWiggle.to(".ear-right", 0.1, { rotation: 0 });
    earWiggle.to(".ear-right", 0.1, { rotation: 45 });
    earWiggle.to(".ear-right", 0.1, { rotation: 0 });
    earWiggle.to(".ear-right", 0.1, { rotation: 45 });
    earWiggle.to(".ear-right", 0.1, { rotation: 0 });

    window.setInterval(() => earWiggle.play(0), 3500);

    // ------------
    // Eye tracking
    // ------------

    const eyeRightPupil = document.querySelector(".eye-right-pupil");
    const eyeLeftPupil = document.querySelector(".eye-left-pupil");
    const eyeLeftInner = document.querySelector(".eye-left-inner");
    const innerEyeWidth = eyeLeftInner.getBoundingClientRect().width;
    const innerEyeHeight = eyeLeftInner.getBoundingClientRect().height;
    const pupilWidth = eyeLeftPupil.getBoundingClientRect().width;
    const pupilHeight = eyeLeftPupil.getBoundingClientRect().height;

    window.addEventListener("mousemove", updateEyePosition);

    function updateEyePosition(e) {
      const mousePercentX = e.clientX / document.body.clientWidth;
      const mousePercentY = e.clientY / document.body.clientHeight;
      const posX = ((mousePercentX * 2 - 1) * (innerEyeWidth - pupilWidth)) / 2;
      const posY =
        ((mousePercentY * 2 - 1) * (innerEyeHeight - pupilHeight)) / 2;

      eyeLeftPupil.style.transform = `translate(${posX}px, ${posY}px)`;
      eyeRightPupil.style.transform = `translate(${posX}px, ${posY}px)`;
    }
  }, []);

  return (
    <>
      <div className="hippo">
        <div className="hippo__buttons-container">
          <a className="hippo__download" href={cv} title="" download>
            <button className="hippo__cv-button">DOWNLOAD CV</button>
          </a>

          <div className="hippo__social-container">
            <a className="hippo__download" href="https://github.com/effip24">
              <i className="hippo__social hippo__social_type_gh"></i>
            </a>

            <a
              className="hippo__download"
              href="https://www.linkedin.com/in/ephraim-phil-487a8b190/"
            >
              <i className="hippo__social hippo__social_type_li"></i>
            </a>
          </div>
        </div>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 242 109"
          xmlSpace="preserve"
        >
          <g className="ears">
            <g className="ear-left">
              <ellipse
                className="ear-left-outer"
                transform="matrix(0.9391 -0.3436 0.3436 0.9391 -3.6062 17.8444)"
                cx="48.5"
                cy="19.1"
                rx="11.4"
                ry="13.8"
              />
              <ellipse
                className="ear-left-inner"
                transform="matrix(0.9391 -0.3436 0.3436 0.9391 -3.8876 17.4659)"
                cx="47.3"
                cy="19.7"
                rx="7.3"
                ry="11.2"
              />
            </g>
            <g className="ear-right">
              <ellipse
                className="ear-right-outer"
                transform="matrix(0.3436 -0.9391 0.9391 0.3436 106.5379 189.869)"
                cx="189.1"
                cy="18.7"
                rx="14.4"
                ry="11.9"
              />
              <ellipse
                className="ear-right-inner"
                transform="matrix(0.3436 -0.9391 0.9391 0.3436 106.8522 191.5127)"
                cx="190.4"
                cy="19.3"
                rx="11.7"
                ry="7.7"
              />
            </g>
          </g>
          <g className="eyes">
            <g className="eye-right">
              <path
                className="eye-right-outer"
                d="M174.9,27H186c0-0.3,0-0.7,0-1c0-14.4-11.6-26-26-26c-14.4,0-26,11.6-26,26 c0,0.3,0,0.7,0,1h6.1H174.9z"
              />
              <path
                className="eye-right-inner"
                d="M175,25c0-11-7.8-20-17.5-20S140,14,140,25c0,0.7,0,1.3,0.1,2h34.8 C175,26.3,175,25.7,175,25z"
              />
              <defs>
                <mask id="eye-right-mask" fill="#ffffff">
                  <path d="M175,25c0-11-7.8-20-17.5-20S140,14,140,25c0,0.7,0,1.3,0.1,2h34.8 C175,26.3,175,25.7,175,25z" />
                </mask>
              </defs>
              <g mask="url(#eye-right-mask)">
                <circle className="eye-right-pupil" cx="158" cy="20" r="5" />
              </g>
            </g>
            <g className="eye-left">
              <path
                className="eye-left-outer"
                d="M96.9,27h6.1c0-0.3,0-0.7,0-1c0-14.4-11.6-26-26-26C62.6,0,51,11.6,51,26 c0,0.3,0,0.7,0,1h11.1H96.9z"
              />
              <path
                className="eye-left-inner"
                d="M97,25c0-11-7.8-20-17.5-20S62,14,62,25c0,0.7,0,1.3,0.1,2h34.8C97,26.3,97,25.7,97,25z"
              />
              <defs>
                <mask id="eye-left-mask" fill="#ffffff">
                  <path d="M97,25c0-11-7.8-20-17.5-20S62,14,62,25c0,0.7,0,1.3,0.1,2h34.8C97,26.3,97,25.7,97,25z" />
                </mask>
              </defs>
              <g mask="url(#eye-left-mask)">
                <circle className="eye-left-pupil" cx="80" cy="20" r="5" />
              </g>
            </g>
          </g>
          <g className="nostrils">
            <g className="nostril-right">
              <ellipse
                className="nostril-right-outer"
                cx="130.5"
                cy="27.5"
                rx="6.5"
                ry="5.5"
              />
              <circle className="nostril-right-inner" cx="130" cy="28" r="4" />
            </g>
            <g className="nostril-left">
              <ellipse
                className="nostril-left-outer"
                cx="106.5"
                cy="27.5"
                rx="6.5"
                ry="5.5"
              />
              <circle className="nostril-left-inner" cx="107" cy="28" r="4" />
            </g>
          </g>
          <path
            className="body"
            d="M218,98H24C10.8,98,0,87.2,0,74V51c0-13.2,10.8-24,24-24h194c13.2,0,24,10.8,24,24v23 C242,87.2,231.2,98,218,98z"
          />
          <g className="freckles">
            <circle className="freckle" cx="13.7" cy="41.4" r="1.6" />
            <circle className="freckle" cx="20.1" cy="44.7" r="1.6" />
            <circle className="freckle" cx="19.6" cy="37.8" r="1.6" />
          </g>
          <defs>
            <mask id="mouthMask">
              <path
                fill="#ffffff"
                className="mask"
                d="M218,98H24C10.8,98,0,87.2,0,74V51c0-13.2,10.8-24,24-24h194c13.2,0,24,10.8,24,24v23 C242,87.2,231.2,98,218,98z"
              />
            </mask>
          </defs>
          <g className="mouth" mask="url(#mouthMask)">
            <g className="mouth-pieces">
              <path
                className="mouth-back"
                d="M23.6,168.2l-3-56.1c0-7.8,6.4-14.1,14.1-14.1h172.4c7.8,0,14.1,6.4,14.1,14.1l-3,56.1"
              />
              <path
                className="tongue"
                d="M174.9,168.2c-7.3-5-24.5-9.9-54.8-9.9s-48,5.1-54.8,9.9"
              />
            </g>
          </g>
          <g className="teeth">
            <path
              className="tooth-left"
              d="M115,97.9v7.5c0,2-1.7,3.6-3.6,3.6H89.7c-2,0-3.6-1.7-3.6-3.6v-7.5H115z"
            />
            <path
              className="tooth-right"
              d="M154,97.9v7.5c0,2-1.7,3.6-3.6,3.6h-21.7c-2,0-3.6-1.7-3.6-3.6v-7.5H154z"
            />
          </g>
        </svg>
      </div>
    </>
  );
};
export default Hippo;
