const animateLetters = (selector) => {
  const letters = document.querySelectorAll(`.${selector}`);

  letters.forEach((letter, id) => {
    setTimeout(() => {
      letter.classList.add(`${selector}_show`);
      letter.classList.add(`${selector}_animate`);
    }, 500 + id * 70);
  });
};
export default animateLetters;
