import "./Projects.css";
import { useEffect } from "react";

import FadeIn from "../FadeIn/FadeIn";
import animateLetters from "../../utils/letterAnimation";
import news from "../../images/projects/news.jpg";
import around from "../../images/projects/around.jpg";
import pizza from "../../images/projects/pizza.jpg";
import movie from "../../images/projects/movie.jpg";
import audiophile from "../../images/projects/audiophile.jpg";
import invoice from "../../images/projects/invoice.jpg";
import countries from "../../images/projects/countries.png";
import markdown from "../../images/projects/markdown.jpg";

import Project from "../Project/Project";

const Projects = () => {
  useEffect(() => {
    const element = document.querySelector(".projects__title");

    const observer = new IntersectionObserver(
      (enteries) => {
        enteries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateLetters("projects__letter");
          }
        });
      },
      {
        threshold: 1,
      }
    );
    observer.observe(element);
  }, []);

  return (
    <FadeIn>
      <section id="projects" className="projects">
        <div className="projects__container">
          <h4 className="projects__title">
            <span className="projects__letter">L</span>
            <span className="projects__letter">a</span>
            <span className="projects__letter">t</span>
            <span className="projects__letter">e</span>
            <span className="projects__letter">s</span>
            <span className="projects__letter">t</span>
            &nbsp;
            <span className="projects__letter">P</span>
            <span className="projects__letter">r</span>
            <span className="projects__letter">o</span>
            <span className="projects__letter">j</span>
            <span className="projects__letter">e</span>
            <span className="projects__letter">c</span>
            <span className="projects__letter">t</span>
            <span className="projects__letter">s</span>
          </h4>

          <ul className="projects__list">
            <Project
              title={"News Explorer"}
              image={news}
              description={"HTML5, CSS3, JS, ReactJs, NodeJs, Express.Js"}
              live={"https://news-explorer-effip.netlify.app"}
              github={"https://github.com/effip24/news-explorer-full.git"}
            />

            <Project
              title={"Around the U.S"}
              image={around}
              description={"HTML5, CSS3, JS, ReactJs, NodeJs, Express.Js"}
              live={"https://around-us-effip.netlify.app"}
              github={"https://github.com/effip24/react-around-full.git"}
            />

            <Project
              title={"Invoice web app"}
              image={invoice}
              description={"HTML5, CSS3, JS, ReactJs"}
              live={"https://invoice-app-effip.netlify.app/"}
              github={"https://github.com/effip24/invoice-webapp-frontend.git"}
            />

            <Project
              title={"Entertainment web app"}
              image={movie}
              description={"HTML5, CSS3, JS, ReactJs"}
              live={"https://entertainment-app-effip24.netlify.app/"}
              github={
                "https://github.com/effip24/entertainment-web-app-full.git"
              }
            />

            <Project
              title={"In browser markdown editor"}
              image={markdown}
              description={"HTML5, CSS3, JS, TypeScript"}
              live={"https://markdown-editor-effip.netlify.app/"}
              github={"https://github.com/effip24/in-browser-markdown-editor"}
            />

            <Project
              title={"Countries"}
              image={countries}
              description={"HTML5, CSS3, TypeScript, ReactJs"}
              live={"https://rest-countries-effip.netlify.app/"}
              github={"https://github.com/effip24/rest-countries-frontend.git"}
            />

            <Project
              title={"Audiophile e-commerce"}
              image={audiophile}
              description={"HTML5, CSS3, JS, ReactJs"}
              live={"https://audiophile-effip.netlify.app/"}
              github={
                "https://github.com/effip24/audiophile-ecommerce-front.git"
              }
            />

            <Project
              title={"Pizza ecommerce"}
              image={pizza}
              description={" HTML5, CSS3, JS, ReactJs, NodeJs, Express.Js"}
              live={"https://pizza-effip24.netlify.app/"}
              github={"https://github.com/effip24/pizza-e-commerce-full.git"}
            />
          </ul>
        </div>
      </section>
    </FadeIn>
  );
};
export default Projects;
