import "./Contact.css";

import FadeIn from "../FadeIn/FadeIn";

import useFormAndValidation from "../../utils/useFormAndValidation";
import emailjs from "@emailjs/browser";
import { useEffect, useRef } from "react";

const Contact = () => {
  const form = useRef();
  const { values, handleChange, errors, isValid, resetForm, setValues } =
    useFormAndValidation();

  useEffect(() => {
    resetForm();
    setValues({});
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_880c5tq",
        "template_w5j50jp",
        form.current,
        "DwKAKKPhMHGfLvWIN"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    resetForm();
    return;
  };
  return (
    <FadeIn>
      <section id="contact" className="contact">
        <div className="contact__container">
          <h5 className="contact__title">Contact</h5>
          <div className="contact__form-container">
            <form ref={form} className="contact__form" onSubmit={handleSubmit}>
              <span
                className="hero__tag"
                style={{ top: "-30px", left: "0px" }}
              >{`<form>`}</span>
              <div className="contact__input-container">
                <p className="contact__input-name">
                  FULL NAME <span className="contact__input-bold">*</span>
                </p>
                <input
                  name="user_name"
                  required
                  type="text"
                  pattern="[A-Za-z\s]{1,32}"
                  className="contact__input"
                  onChange={handleChange}
                  value={values.user_name || ""}
                ></input>
                <span className="contact__input-error">{errors.user_name}</span>
              </div>

              <div className="contact__input-container">
                <p className="contact__input-name">
                  EMAIL<span className="contact__input-bold">*</span>
                </p>
                <input
                  name="user_email"
                  required
                  type="email"
                  className="contact__input"
                  onChange={handleChange}
                  value={values.user_email || ""}
                ></input>
                <span className="contact__input-error">
                  {errors.user_email}
                </span>
              </div>

              <div className="contact__input-container">
                <p className="contact__input-name">
                  MESSAGE <span className="contact__input-bold">*</span>
                </p>
                <textarea
                  name="message"
                  required
                  type="text"
                  className="contact__text"
                  onChange={handleChange}
                  value={values.message || ""}
                ></textarea>
                <span className="contact__input-error">{errors.message}</span>
              </div>

              <button
                aria-label="submit"
                className={`contact__submit ${
                  isValid ? "" : "contact__submit_inactive"
                }`}
                type="submit"
              >
                SEND
              </button>

              <span
                className="hero__tag"
                style={{ bottom: "-35px", left: "0px" }}
              >{`<form />`}</span>
            </form>

            <div className="contact__about-container">
              <p className="contact__about-title">Ephraim Phil</p>
              <div className="contact__about-info-container">
                <p className="contact__about-info-name">RESIDENCE: </p>
                <p className="contact__about-info-text">
                  Merchavia kibbutz (mooving to Nathanya), Israel
                </p>
              </div>

              <div className="contact__about-info-container">
                <p className="contact__about-info-name">E-MAIL: </p>
                <p className="contact__about-info-text">effi188@gmail.com</p>
              </div>

              <div className="contact__about-info-container">
                <p className="contact__about-info-name">PHONE: </p>
                <p className="contact__about-info-text">0502807003</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </FadeIn>
  );
};
export default Contact;
