import "./Skills.css";
import { useEffect } from "react";
import animateLetters from "../../utils/letterAnimation";
import skillAnimation from "../../utils/skillAnimation";

import FadeIn from "../FadeIn/FadeIn";

import html from "../../images/skills/html.png";
import css from "../../images/skills/css.png";
import js from "../../images/skills/js.png";
import ts from "../../images/skills/typescript.png";
import react from "../../images/skills/react.png";
import nodejs from "../../images/skills/nodejs.png";
import express from "../../images/skills/Expressjs.png";
import mongo from "../../images/skills/mongo.png";
import git from "../../images/skills/git.png";
import figma from "../../images/skills/figma.png";

const Skills = () => {
  useEffect(() => {
    const element = document.querySelector(".skills__title");
    const skills = document.querySelectorAll(".skills__list");

    const titleObserver = new IntersectionObserver(
      (enteries) => {
        enteries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateLetters("skills__letter");
          }
        });
      },
      {
        threshold: 1,
      }
    );
    titleObserver.observe(element);

    const skillsObserver = new IntersectionObserver(
      (enteries) => {
        enteries.forEach((entry) => {
          if (entry.isIntersecting) {
            skillAnimation(entry.target);
          }
        });
      },
      {
        threshold: 0.3,
      }
    );
    skills.forEach((skill) => {
      skillsObserver.observe(skill);
    });
  }, []);

  return (
    <FadeIn>
      <section id="skills" className="skills">
        <div className="skills__container">
          <h3 className="skills__title">
            <span className="about__letter">M</span>
            <span className="about__letter">y</span>
            &nbsp;
            <span className="about__letter">S</span>
            <span className="about__letter">k</span>
            <span className="about__letter">i</span>
            <span className="about__letter">l</span>
            <span className="about__letter">l</span>
            <span className="about__letter">s</span>
          </h3>

          <ul className="skills__list">
            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={html} alt="icon"></img>
              <p className="skills__skill-name">HTML5</p>
            </li>

            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={css} alt="icon"></img>
              <p className="skills__skill-name">CSS3</p>
            </li>

            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={js} alt="icon"></img>
              <p className="skills__skill-name">JavaScript</p>
            </li>

            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={ts} alt="icon"></img>
              <p className="skills__skill-name">TypeScript</p>
            </li>

            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={react} alt="icon"></img>
              <p className="skills__skill-name">React</p>
            </li>
          </ul>

          <ul className="skills__list">
            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={nodejs} alt="icon"></img>
              <p className="skills__skill-name">NodeJs</p>
            </li>

            <li className="skills__skill-container">
              <img
                className="skills__skill-icon"
                src={express}
                alt="icon"
              ></img>
              <p className="skills__skill-name">ExpressJs</p>
            </li>

            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={mongo} alt="icon"></img>
              <p className="skills__skill-name">MongoDB</p>
            </li>
          </ul>

          <ul className="skills__list">
            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={git} alt="icon"></img>
              <p className="skills__skill-name">Git</p>
            </li>

            <li className="skills__skill-container">
              <img className="skills__skill-icon" src={figma} alt="icon"></img>
              <p className="skills__skill-name">Figma</p>
            </li>
          </ul>
        </div>
      </section>
    </FadeIn>
  );
};
export default Skills;
