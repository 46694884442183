import "./Footer.css";

import logo from "../../images/footer/footer-logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__upper">
          <div className="footer__left-side">
            <div className="footer__logo-container">
              <img className="footer__logo" alt="footer" src={logo}></img>
              <p className="footer__name">EPHRAIM PHIL</p>
            </div>
          </div>
          <div className="footer__right-side">
            <ul className="footer__contact-container">
              <li className="footer__contact">
                <i className="footer__contact-icon footer__contact-icon_type_email"></i> effi188@gmail.com
              </li>
              <li className="footer__contact">
                <i className="footer__contact-icon footer__contact-icon_type_phone"></i> +972 50-280-7003
              </li>
            </ul>

            <ul className="footer__social">
              <li className="footer__social-link">
                <a className="footer__social-link-icon-wrap" href="https://www.linkedin.com/in/effip">
                  <i className="footer__social-icon footer__social-icon_type_li"></i>
                </a>
              </li>

              <li className="footer__social-link">
                <a className="footer__social-link-icon-wrap" href="https://www.github.com/effip24">
                  <i className="footer__social-icon footer__social-icon_type_gh"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <p className="footer__copyrights">© All Right Reserved</p>
      </div>
    </footer>
  );
};
export default Footer;
