import "./SlideShow.css";

import me from "../../images/about/me.jpg";

const SlideShow = () => {
  return (
    <section className="slide-show">
      <div className="slide-show__slider">
        <img className="slide-show__img" src={me} alt={"me"}></img>
      </div>
    </section>
  );
};
export default SlideShow;
