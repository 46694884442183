import React from "react";
import "./App.css";
import emailjs from "@emailjs/browser";

import Header from "../SideBar/SideBar";
import Main from "../Main/Main";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    fetch("https://ipapi.co/json/", {
      mode: "cors",
    })
      .then((resp) => resp.json())
      .then((visitor) => {
        emailjs.send(
          "service_880c5tq",
          "template_9uqufge",
          { ip: visitor.ip, country: visitor.country_name, city: visitor.city },
          "DwKAKKPhMHGfLvWIN"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="App">
      <Header />
      <Main />
    </div>
  );
}

export default App;
