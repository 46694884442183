import { useState } from "react";

import "./SideBar.css";

import mobile from "../../images/mobile-icon.png";

import Navigation from "../Navigation/Navigation";

const SideBar = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleSidebarHoverIn = () => {
    setIsHover(true);
  };

  const handleSidebarHoverLeave = () => {
    setIsHover(false);
  };

  const handleMobileClick = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  return (
    <>
      <img className="side-bar__mobile" src={mobile} alt="mobile" onClick={handleMobileClick}></img>
      <aside
        className={`side-bar ${isMobileOpen ? "side-bar_show" : ""}`}
        onMouseEnter={handleSidebarHoverIn}
        onMouseLeave={handleSidebarHoverLeave}
      >
        <div className="side-bar__logo-container">
          <p className="side-bar__logo">E</p>
        </div>

        <Navigation isHover={isHover} />

        <div></div>
      </aside>
    </>
  );
};
export default SideBar;
