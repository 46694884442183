import "./Hero.css";
import { useEffect } from "react";
import animateLetters from "../../utils/letterAnimation";
import Hippo from "../Hippo/Hippo";

import cv from "../../utils/ephraim-cv.docx";

const Hero = () => {
  useEffect(() => {
    let parallax = (e) => {
      document.querySelectorAll(".hero__image").forEach((image) => {
        const speed = image.getAttribute("data-speed");
        const x =
          ((window.innerWidth - e.pageX) / window.innerWidth) * speed * 6;
        const y =
          ((window.innerHeight - e.pageY) / window.innerHeight) * speed * 6;

        image.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    };
    document.addEventListener("mousemove", parallax);
    animateLetters("hero__letter");
  }, []);

  return (
    <section id="home" className="hero">
      <div className="hero__main-container">
        <div className="hero__info-container">
          <h1 className="hero__title">
            <span className="hero__letter">H</span>
            <span className="hero__letter">i</span>
            <span className="hero__letter">,</span>
            <br></br>
            <span className="hero__letter">I</span>
            <span className="hero__letter">׳</span>
            <span className="hero__letter">m</span>
            &nbsp;
            <span className="hero__letter">E</span>
            <span className="hero__letter">p</span>
            <span className="hero__letter">h</span>
            <span className="hero__letter">r</span>
            <span className="hero__letter">a</span>
            <span className="hero__letter">i</span>
            <span className="hero__letter">m</span>
            <span className="hero__letter">,</span>
            <br></br>
            <span className="hero__letter">A</span>
            &nbsp;
            <span className="hero__letter">W</span>
            <span className="hero__letter">e</span>
            <span className="hero__letter">b</span>
            &nbsp;
            <span className="hero__letter">d</span>
            <span className="hero__letter">e</span>
            <span className="hero__letter">v</span>
            <span className="hero__letter">e</span>
            <span className="hero__letter">l</span>
            <span className="hero__letter">o</span>
            <span className="hero__letter">p</span>
            <span className="hero__letter">e</span>
            <span className="hero__letter">r</span>
            <span className="hero__letter">.</span>
          </h1>

          <div className="hero__bottom-container">
            <p className="hero__subtitle">Fullstack web developer</p>

            <div className="hero__buttons-container">
              <a className="hero__download" href={cv} title="" download>
                <button className="hero__cv-button">DOWNLOAD CV</button>
              </a>

              <div className="hero__social-container">
                <a className="hero__download" href="https://github.com/effip24">
                  <i className="hero__social hero__social_type_gh"></i>
                </a>

                <a
                  className="hero__download"
                  href="https://www.linkedin.com/in/ephraim-phil-487a8b190/"
                >
                  <i className="hero__social hero__social_type_li"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="hero__background">
          <img className="hero__image" data-speed="-1" src={one} alt="bg"></img>
          <img className="hero__image" data-speed="2" src={two} alt="bg"></img>
          <img
            className="hero__image"
            data-speed="9"
            src={three}
            alt="bg"
          ></img>
          <img className="hero__image" data-speed="3" src={four} alt="bg"></img>
        </div>*/}
        <Hippo />
      </div>
    </section>
  );
};
export default Hero;
